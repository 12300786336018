<template>
  <div>
    <div class="title">修改成功</div>
    <div style="text-align:center;margin-top:1.79rem">
      <img style="width:1.4rem;height:1.4rem" src="" alt="">
      <div class="txt">成功修改密码！</div>
    </div>
    <div class="btn" @click="goLogin">修改成功，去登录</div>
  </div>
</template>
<script>
export default {
  methods:{
    goLogin(){
      this.$router.push("./login")
    },
  }
}
</script>
<style scoped>
.txt{
  font-size: 0.4rem;
font-family: PingFangSC-Medium, PingFang SC;
font-weight: 500;
color: #222222;
margin-top:0.72rem;
}
.btn{
width: 6.86rem;
height: 0.96rem;
background: linear-gradient(90deg, #F55613 0%, #FC9E46 100%);
border-radius: 0.48rem;
line-height: 0.96rem;
text-align: center;
font-size: 0.36rem;
font-family: PingFangSC-Medium, PingFang SC;
font-weight: 500;
color: #FFFFFF;
margin:0 auto;
margin-top:5rem;
}
.title{
  height:1rem;
  border-bottom: 0.01rem solid #ddd;
  line-height: 1rem;
  text-align: center;
  font-size: 0.34rem;
font-family: PingFangSC-Medium, PingFang SC;
font-weight: 500;
color: #EF811A;
}

</style>